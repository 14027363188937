import React, { Component } from 'react';

import Grid from '@mui/material/Grid';

import Api from 'api/Api';

import moment from 'moment';

import Main from 'components/Main';

import ProfessionalsCard from 'sections/ProfessionalsCard';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

class Dashboard extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,
            user: false,

            logged_in: false,

            reload_grid: false,

            error_message: '',
            success_message: '',

            professionals: [],

            post_params: [],

            monthly: 0,
            weekly: 0,
            daily: 0,
        }
    }

    componentDidMount = () => {
        
        let account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        let user = localStorage.getItem(process.env.REACT_APP_ACCOUNT_USER);
        
        if(account_token && user){

            this.setState({account_token: account_token}, () => {

                this.init(account_token)
            });
        }else{

            // window.location = Api.url + 'logout';
        }
    }

    render(){

        const slider_config = {
            prevArrow: <button type="button" className="slick-prev">
                            <div>
                                <ChevronLeft className='text-white' />
                            </div>
                        </button>,
            nextArrow: <button type="button" className="slick-next">
                            <div>
                                <ChevronRight className='text-white' />
                            </div>
                        </button>,
        };

        return (

            <Main
                page="dashboard"
                active_page="dashboard"
                error_message={this.state.error_message}
                success_message={this.state.success_message}
            >
                <Grid container spacing={2} className='mt-10'>

                    <Grid item xs={6} lg={6}>
                        <div className='dashboard-welcome'>
                            
                            <div className='dashboard-date-block'>
                                <div className='dashboard-date'>
                                    <span>{moment(new Date(), 'DD-MM-YYYY').format('D')}</span>
                                </div>
                                <div className='dashboard-month'>
                                    <span>{moment(new Date(), 'DD-MM-YYYY').format('dddd')}</span>
                                    <strong>{moment(new Date(), 'DD-MM-YYYY').format('MMMM, Y')}</strong>
                                </div>
                            </div>
                            
                            <strong>Welcome To</strong>
                            <h1>LinkFit Dashboard</h1>
                        </div>
                    </Grid>

                    <Grid item xs={6} lg={2}>
                        <div className='dash-stat-card'>

                            <div className='dash-stat-card-stats'>

                                <div className='dash-stat-card-stat-number'>
                                    <strong>{this.state.daily}</strong>
                                    <span>Registrations</span>
                                </div>
                                
                                <h2>Today</h2>
                            </div>
                            <span className='bg-text'>D</span>
                        </div>
                    </Grid>

                    <Grid item xs={6} lg={2}>
                        <div className='dash-stat-card'>

                            <div className='dash-stat-card-stats'>

                                <div className='dash-stat-card-stat-number'>
                                    <strong>{this.state.weekly}</strong>
                                    <span>Registrations</span>
                                </div>

                                <h2>Week</h2>
                            </div>
                            <span className='bg-text'>W</span>
                        </div>
                    </Grid>

                    <Grid item xs={6} lg={2}>
                        <div className='dash-stat-card'>

                            <div className='dash-stat-card-stats'>

                                <div className='dash-stat-card-stat-number'>
                                    <strong>{this.state.monthly}</strong>
                                    <span>Registrations</span>
                                </div>

                                <h2>Month</h2>
                            </div>
                            <span className='bg-text'>M</span>
                        </div>
                    </Grid>

                    <Grid item xs={12} lg={12}>

                        <div style={{margin:'25px 0px 50px 0', borderBottom: '1px solid rgba(0,0,0,.2)'}}></div>

                        <h3 className='fs-20'>Latest Registrations</h3>

                        <Slider
                            {...slider_config}
                            slidesToShow={this.state.professionals.length >= 3 ? 3 : this.state.professionals.length}
                            slidesToScroll={1}
                            fade={false}
                            arrows={true}
                            dots={this.state.professionals.length >= 3 ? true : false}
                            infinite={false}
                            responsive={[{breakpoint: 600, settings: {slidesToShow: 1, slidesToScroll: 1, centerMode: false, infinite: false, dots: true}}]}
                            className="items-slider"
                        >
                            {this.state.professionals.map((_professional, index) => {

                                return (
                                    <div key={`professional_${index}`}>
                                        <div className='m-2 flex items-center justify-center professionals-slider-item'>
                                            <ProfessionalsCard
                                                key={`professional_${index}`}
                                                row={_professional}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>

                        {/* <div className='justify-start'>

                            {this.state.professionals.map((_professional, index) => {

                                return (
                                    <ProfessionalsCard
                                        key={`professional_${index}`}
                                        row={_professional}
                                    />
                                )
                            })}
                            
                        </div> */}
                    </Grid>
                </Grid>
            </Main>
        )
    }

    init = (account_token) => {

        var formData = new FormData();
        var that = this;

        formData.append('account_token', account_token);

        Api.post('backend/dashboard', formData, function(data){

            if(data.status){
            
                that.setState({
                    professionals: data.professionals,
                    monthly: data.monthly,
                    weekly: data.weekly,
                    daily: data.daily
                });
            }else{

                // LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }
}

export default Dashboard;