"use client"

import React from 'react';

export default function Ratings(props){

    const star = () => {

        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/></svg>
        )
    }

    const renderRating = () => {

        let ratings = [];

        if(props.rating && props.rating > 0){

            for(let r = 1; r <= props.rating; r++){

                ratings.push(<span key={`rate_${r}`} className={`c-blue`}>{star()}</span>)
            }

            for(let rm = (props.rating); rm < 5; rm++){

                ratings.push(<span key={`rate_disabled_${rm}`} className={`c-blue op-3`}>{star()}</span>)
            }
        }

        return ratings;
    }

    return (

        <>
            {(props.rating && props.rating > 0) &&
            
                <div className='flex'>
                
                    {renderRating()}
                </div>
            }
        </>
    )
}