import React, { Component } from 'react';

import WdForm from 'components/wd/WdForm';

import Grid from '@mui/material/Grid';

import NoData from 'components/blocks/NoData';

import Api from 'api/Api';

import Btn from 'components/Btn';

import moment from 'moment';

import Skeleton from '@mui/material/Skeleton';

import TextSnippet from '@mui/icons-material/TextSnippet';
import Description from '@mui/icons-material/Description';
import Badge from '@mui/icons-material/Badge';

import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class ProfessionalsTestimonials extends Component { 
    constructor(props) {
        super();
        this.state = {

            initing: false,

            row: false,

            form_popup: false,

            valid_from: '',

            from_years: [],
            till_years: [],

            removing: false,

            types: [],
            type: '',

            data: []
        }
    }

    componentDidMount = () => {

        const _year = new Date().getFullYear();
        const current_date = moment();

        const _max_year = current_date.add('8', 'years').format('YYYY');

        let _from = [];
        let _till = [];

        for(let from = _year; from >= 1971; from--){

            _from.push({key: from, value: from})
        }

        let valid_from = this.state.valid_from;

        let _till_end = 1971;

        if(valid_from !== ''){

            _till_end = valid_from;
        }

        for(let till = _max_year; till >= parseInt(_till_end); till--){

            _till.push({key: till, value: till})
        }

        this.setState({from_years: _from, till_years: _till})

        this.load(this.props.account_token)
    }

    render () {

        const slider_config = {
            prevArrow: <button type="button" className="slick-prev">
                            <svg width="32" height="32" viewBox="0 0 24 24" className='text-white' fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <polyline points="15 18 9 12 15 6"></polyline>
                            </svg>
                        </button>,
            nextArrow: <button type="button" className="slick-next">
                            <svg width="32" height="32" viewBox="0 0 24 24" className='text-white' fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </button>,
        };

        return (
            
            <>
                <Grid container spacing={2}>

                    {this.state.initing
                        ?
                            <>
                                <Grid item xs={12} lg={12}>
                                    <Skeleton animation="wave" variant="rect" width={'100%'} height={200} style={{borderRadius:10}} />
                                </Grid>
                            </>
                        :
                            this.state.data.length > 0
                                ?
                                    <Grid item xs={12} lg={12}>

                                        <div className='justify-start'>
                                            <div style={{width: 1000, position: 'relative'}}>
            
                                                <Slider
                                                    {...slider_config}
                                                    slidesToShow={1}
                                                    slidesToScroll={1}
                                                    fade={false}
                                                    arrows={true}
                                                    dots={true}
                                                    infinite={false}
                                                    responsive={[{breakpoint: 480,  settings: {slidesToShow: 1, slidesToScroll: 1, centerMode: false}}]}
                                                    className="main-slider"
                                                >
                                                    {this.state.data.map((_row, index) => {

                                                        return (
                                                            <div key={`_row_${index}`}>
                                                                <div className='list-card-container'>
                                                                    <div className='list-card'>
                                                                        <div className='list-card-block'>
                                                                            
                                                                            <Grid container spacing={2}>
                                                                                <Grid item xs={12} lg={2}>

                                                                                    {_row.type === 'i'
                                                                                        ?
                                                                                            <a href={_row.image_url} target='_blank'>

                                                                                                <div style={{width: '100%', height: 140, backgroundImage: `url(${_row.image_url})`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: 10}}>

                                                                                                </div>
                                                                                            </a>
                                                                                        :
                                                                                            <div className='vertical align-center'>
                                                                                                <Description style={{fontSize: 100}} className='gr-2' />
                                                                                                <span className='fs-12'>No Document</span>
                                                                                            </div>
                                                                                    }
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={10}>

                                                                                    <div className='align-start vertical'>
                                                                                    
                                                                                        <h2 className='mt-2 mb-0'>{_row.name} <span className='gr-3'>({_row.type_label})</span></h2>

                                                                                        {_row.age !== '' &&
                                                                                        
                                                                                            <div className='align-center mb-10'>
                                                                                                <Badge style={{fontSize: 16}} className='gr-5' />
                                                                                                <strong className='fs-14 gr-5 ml-5'>Age: {_row.age}</strong>
                                                                                            </div>
                                                                                        }

                                                                                        <div className='align-start vertical mt-10'>
                                                                                            <strong className='fs-11 gr-5 c-blue'>DETAILS</strong>
                                                                                            <p className='gr-6 fs-13 mt-2'>{_row.description}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <div className='list-card-actions'>
                                                                                <Btn startIcon={<Delete style={{fontSize: 18}} className='c-red' />} confirm={true} confirm_message="Do you really want to remove this entry?" onClick={() => {

                                                                                    this.remove(_row)
                                                                                }} loading={this.state.removing === _row.row_id ? true : false}>
                                                                                    <span className='c-red'>Remove</span>
                                                                                </Btn>

                                                                                <Btn startIcon={<Edit style={{fontSize: 16}} />} onClick={() => {

                                                                                    this.setState({row: _row}, () => {

                                                                                        this.setState({form_popup: true})
                                                                                    })
                                                                                }}>
                                                                                    Edit
                                                                                </Btn>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </Slider>
                                            </div>
                                        </div>

                                        <div className='justify-center mt-10' style={{marginTop: 100}}>
                                            <div style={{marginTop: 50}}>
                                                <Btn color="primary" variant="contained" onClick={() => {

                                                    this.setState({form_popup: true})
                                                }}>Add New Testimonial</Btn>
                                            </div>
                                        </div>
                                    </Grid>
                                :
                                    <Grid item xs={12} lg={12}>
                                        <NoData size="small" message="Testimonials not added yet.">
                                            <Btn color="primary" variant="contained" onClick={() => {

                                                this.setState({form_popup: true})
                                            }}>Add New Testimonial</Btn>
                                        </NoData>
                                    </Grid>
                    }
                </Grid>

                <WdForm
                            
                    title='Testimonial'
                    back_label="Cancel"

                    drawer={true}
                    open={this.state.form_popup}

                    size="medium"

                    position="bottom"

                    submit_url='backend/professionals/testimonials/save'
                    data_url='backend/professionals/testimonials/data'

                    post_fields={[
                        {key: 'professional_id', value: this.props.row_id}
                    ]}

                    onSubmit={(result) => {

                        this.setState({form_popup: false})

                        this.props.successMessage(result.message);

                        this.load(this.props.account_token)
                        
                    }}
                    onBack={() => {

                        this.setState({form_popup: false, active_row: false, row_id: '', row: false})
                    }}
                
                    row_id={this.state.row.row_id}
                    id="row_id"
                    title_field="type_label"
                    updated_on="updated_on_formatted"
                                            
                    fields={{
                        rows: [
                            {
                                fields: [
                                    {key: 'type', type: 'radio', name: 'type', label: 'Type', validations: ['r'], span: 12, options: this.state.types, onChange: (value) => {

                                        this.setState({type: value})
                                    }}
                                ]
                            },
                            {
                                fields: [
                                    {key: 'name', type: 'input', name: 'name', label: 'Name', validations: ['r'], span: 12}
                                ]
                            },
                            {
                                fields: [
                                    {key: 'age', type: 'input', name: 'age', label: 'Age', validations: ['r', 'num'], span: 4, remove: this.state.type === 'i' ? false : true}
                                ]
                            },
                            {
                                fields: [
                                    {key: 'youtube_url', type: 'input', name: 'youtube_url', label: 'Youtube URL', validations: ['r'], span: 12, remove: this.state.type === 'y' ? false : true, comment: 'Enter youtube url here.'}
                                ]
                            },
                            {
                                fields: [
                                    {key: 'file', type: 'image', name: 'file', label: 'Image', validations: [], span: 6, path: 'professionals/testimonials/', allowed_types: 'png,jpg', formatted_field: 'image_url', remove: this.state.type === 'i' ? false : true}
                                ]
                            },
                            {
                                fields: [
                                    {key: 'description', type: 'textarea', name: 'description', label: 'Description', validations: ['r'], span: 12, rows: 8}
                                ]
                            },
                        ]
                    }}
                />
            </>
        )
    }

    load = (account_token) => {

        var formData = new FormData();
        var that = this;

        formData.append('account_token', account_token);
        formData.append('professional_id', this.props.row_id);

        this.setState({initing: true})

        Api.post('backend/professionals/testimonials/listing', formData, function(data){

            that.setState({initing: false})

            if(data.status){
            
                that.setState({
                    data: data.records,
                    types: data.types
                });
            }else{

                // LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }

    remove = (row) => {

        if(row.row_id){
    
            var formData = new FormData();
            var that = this;

            formData.append('account_token', this.props.account_token);
            formData.append('row_id', row.row_id);

            this.setState({removing: row.row_id})

            Api.post('backend/professionals/testimonials/remove', formData, function(data){

                that.setState({removing: false})

                if(data.status){
                
                    that.load(that.props.account_token);
                }else{

                    // LayoutHelper.addErrorMessage(that, data.message);
                }
            });
        }
    }
}

export default ProfessionalsTestimonials;
