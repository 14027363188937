import React, { Component } from 'react';

import WdForm from 'components/wd/WdForm';

import Grid from '@mui/material/Grid';

import NoData from 'components/blocks/NoData';

import Api from 'api/Api';

import Btn from 'components/Btn';

import moment from 'moment';

import Skeleton from '@mui/material/Skeleton';

import TextSnippet from '@mui/icons-material/TextSnippet';
import Description from '@mui/icons-material/Description';
import DateRange from '@mui/icons-material/DateRange';

import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class ProfessionalsWorkExperience extends Component { 
    constructor(props) {
        super();
        this.state = {

            initing: false,

            row: false,

            form_popup: false,

            work_from: '',

            from_years: [],
            till_years: [],

            removing: false,

            types: [],
            type: '',

            data: []
        }
    }

    componentDidMount = () => {

        const _year = new Date().getFullYear();
        const current_date = moment();

        const _max_year = current_date.add('8', 'years').format('YYYY');

        let _from = [];
        let _till = [];

        for(let from = _year; from >= 1971; from--){

            _from.push({key: from, value: from})
        }

        let work_from = this.state.work_from;

        let _till_end = 1971;

        if(work_from !== ''){

            _till_end = work_from;
        }

        for(let till = _max_year; till >= parseInt(_till_end); till--){

            _till.push({key: till, value: till})
        }

        this.setState({from_years: _from, till_years: _till})

        this.load(this.props.account_token)
    }

    render () {

        const slider_config = {
            prevArrow: <button type="button" className="slick-prev">
                            <svg width="32" height="32" viewBox="0 0 24 24" className='text-white' fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <polyline points="15 18 9 12 15 6"></polyline>
                            </svg>
                        </button>,
            nextArrow: <button type="button" className="slick-next">
                            <svg width="32" height="32" viewBox="0 0 24 24" className='text-white' fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </button>,
        };

        return (
            
            <>
                <Grid container spacing={2}>

                    {this.state.initing
                        ?
                            <>
                                <Grid item xs={12} lg={12}>
                                    <Skeleton animation="wave" variant="rect" width={'100%'} height={200} style={{borderRadius:10}} />
                                </Grid>
                            </>
                        :
                            this.state.data.length > 0
                                ?
                                    <Grid item xs={12} lg={12}>

                                        <div className='justify-start'>
                                            <div style={{width: 1000, position: 'relative'}}>
            
                                                <Slider
                                                    {...slider_config}
                                                    slidesToShow={1}
                                                    slidesToScroll={1}
                                                    fade={false}
                                                    arrows={true}
                                                    dots={true}
                                                    infinite={false}
                                                    responsive={[{breakpoint: 480,  settings: {slidesToShow: 1, slidesToScroll: 1, centerMode: false}}]}
                                                    className="main-slider"
                                                >
                                                    {this.state.data.map((_row, index) => {

                                                        return (
                                                            <div key={`_row_${index}`}>
                                                                <div className='list-card-container'>
                                                                    <div className='list-card'>
                                                                        <div className='list-card-block'>
                                                                            
                                                                            <Grid container spacing={2}>
                                                                                <Grid item xs={12} lg={10}>

                                                                                    <div className='align-start vertical'>
                                                                                    
                                                                                        <h3 className='mt-2 mb-0'>{_row.work_type}</h3>

                                                                                        <div className='align-center mb-10'>
                                                                                            <DateRange style={{fontSize: 16}} className='gr-5' />
                                                                                            <strong className='fs-14 gr-5'>{_row.work_from} - {_row.work_to}</strong>
                                                                                        </div>

                                                                                        {_row.company_name !== '' &&

                                                                                            <div className='align-start vertical'>
                                                                                                <strong className='fs-11 gr-5 c-blue'>COMPANY</strong>
                                                                                                <strong className='gr-6 fs-13'>{_row.company_name}</strong>
                                                                                            </div>
                                                                                        }

                                                                                        <div className='align-start vertical mt-10'>
                                                                                            <strong className='fs-11 gr-5 c-blue'>DESIGNATION</strong>
                                                                                            <strong className='gr-6 fs-13'>{_row.designation}</strong>
                                                                                        </div>
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <div className='list-card-actions'>
                                                                                <Btn startIcon={<Delete style={{fontSize: 18}} className='c-red' />} confirm={true} confirm_message="Do you really want to remove this entry?" onClick={() => {

                                                                                    this.remove(_row)
                                                                                }} loading={this.state.removing === _row.row_id ? true : false}>
                                                                                    <span className='c-red'>Remove</span>
                                                                                </Btn>

                                                                                <Btn startIcon={<Edit style={{fontSize: 16}} />} onClick={() => {

                                                                                    this.setState({row: _row}, () => {

                                                                                        this.setState({form_popup: true})
                                                                                    })
                                                                                }}>
                                                                                    Edit
                                                                                </Btn>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </Slider>
                                            </div>
                                        </div>

                                        <div className='justify-center mt-10' style={{marginTop: 100}}>
                                            <div style={{marginTop: 50}}>
                                                <Btn color="primary" variant="contained" onClick={() => {

                                                    this.setState({form_popup: true})
                                                }}>Add Work Experience</Btn>
                                            </div>
                                        </div>
                                    </Grid>
                                :
                                    <Grid item xs={12} lg={12}>
                                        <NoData size="small" message="Work Experience not created yet.">
                                            <Btn color="primary" variant="contained" onClick={() => {

                                                this.setState({form_popup: true})
                                            }}>Add Work Experience</Btn>
                                        </NoData>
                                    </Grid>
                    }
                </Grid>

                <WdForm
                            
                    title='Work Experience'
                    back_label="Cancel"

                    drawer={true}
                    open={this.state.form_popup}

                    size="medium"

                    position="bottom"

                    submit_url='backend/professionals/experience/save'
                    data_url='backend/professionals/experience/data'

                    post_fields={[
                        {key: 'professional_id', value: this.props.row_id}
                    ]}

                    onSubmit={(result) => {

                        this.setState({form_popup: false})

                        this.props.successMessage(result.message);

                        this.load(this.props.account_token)
                        
                    }}
                    onBack={() => {

                        this.setState({form_popup: false, active_row: false, row_id: '', row: false})
                    }}
                
                    row_id={this.state.row.row_id}
                    id="row_id"
                    title_field="work_type"
                    updated_on="updated_on_formatted"
                                            
                    fields={{
                        rows: [
                            {
                                fields: [
                                    {key: 'type', type: 'radio', name: 'type', label: 'Exp. Type', validations: ['r'], span: 12, options: this.state.types, onChange: (value) => {

                                        this.setState({type: value})
                                    }}
                                ]
                            },
                            {
                                fields: [
                                    {key: 'company_name', type: 'input', name: 'company_name', label: 'Company Name', validations: ['r'], span: 8, remove: this.state.type === 'e' ? false : true}
                                ]
                            },
                            {
                                fields: [
                                    {key: 'designation', type: 'input', name: 'designation', label: 'Designation', validations: ['r'], span: 8}
                                ]
                            },
                            {
                                fields: [
                                    {key: 'work_from', type: 'dropdown', name: 'work_from', label: 'Work From', validations: ['r'], span: 4, options: this.state.from_years, onChange: (value) => {

                                        this.setState({work_from: value})

                                        let _till = [];

                                        let current_date = moment();

                                        let _max_year = current_date.add('8', 'years').format('YYYY');

                                        for(let till = _max_year; till >= parseInt(value); till--){

                                            _till.push({key: till, value: till})
                                        }
                                
                                        this.setState({till_years: _till})

                                        window.dispatchEvent(
                                            new CustomEvent("wd_field_update", {
                                                bubbles: true,
                                                detail: {name: 'work_to', value: ''},
                                            }),
                                        );
                                    }},
                                    {key: 'work_to', type: 'dropdown', name: 'work_to', label: 'Work To', validations: ['r'], span: 4, options: this.state.till_years}
                                ]
                            },
                        ]
                    }}
                />
            </>
        )
    }

    load = (account_token) => {

        var formData = new FormData();
        var that = this;

        formData.append('account_token', account_token);
        formData.append('professional_id', this.props.row_id);

        this.setState({initing: true})

        Api.post('backend/professionals/experience/listing', formData, function(data){

            that.setState({initing: false})

            if(data.status){
            
                that.setState({
                    data: data.records,
                    types: data.types
                });
            }else{

                // LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }

    remove = (row) => {

        if(row.row_id){
    
            var formData = new FormData();
            var that = this;

            formData.append('account_token', this.props.account_token);
            formData.append('row_id', row.row_id);

            this.setState({removing: row.row_id})

            Api.post('backend/professionals/experience/remove', formData, function(data){

                that.setState({removing: false})

                if(data.status){
                
                    that.load(that.props.account_token);
                }else{

                    // LayoutHelper.addErrorMessage(that, data.message);
                }
            });
        }
    }
}

export default ProfessionalsWorkExperience;
