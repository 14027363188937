import React, { Component } from 'react';
import { Link } from "react-router-dom";

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import Checkbox from '@mui/material/Checkbox';

import Edit from '@mui/icons-material/Edit';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import Api from 'api/Api';

import Btn from 'components/Btn';

import EditableCell from './EditableCell';

import DataTableSubRows from './DataTableSubRows';

class DataTableRows extends Component { 
    constructor(props) {
        super();

        this.state = {

            loading: false,

            load_sub_data: false,
        }
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {

        if(
            this.props.sub_rows
            &&
            this.props.sub_rows.hasOwnProperty('reload')
            &&
            this.props.sub_rows.reload === true
            &&
            this.state.load_sub_data === false 
        ){

            this.setState({load_sub_data: true})

            let data = this.props.data;
            let expanded_row = this.props.expanded_row;

            if(expanded_row > -1){

                let _row = data[expanded_row];
            
                this.loadSubData(_row[this.props.sub_rows.row_id.key], this.props.sub_rows.row_id.field, this.props.sub_rows.api_url, expanded_row)
            }
        }
    }

    render(){

        return(

            this.renderCells()
        )
    }

    renderCells = () => {

        let data = this.props.data;
        let n = this.props.index;
        
        let _row = data[n];
        let _n = n;

        var cols = [];

        this.props.columns.forEach((column, index) => {

            if(column && typeof column == 'object' && column.hasOwnProperty('column')){
            
                var _row_data = _row;
                var row_data = _row[column.column];
                let original_value = row_data;

                if(column.hasOwnProperty('search_data') && column.search_data.length > 0){

                    let _row_data_value = column.search_data.find(row => row.key === row_data);

                    if(_row_data_value){

                        row_data = _row_data_value.value;
                    }
                }

                var row_index = "data_" + n + "_" + index + "_" + column.column;

                if(column.selectable){

                    var check_index = "data_" + n + + "_check";
                    var _column = column.column;
                    
                    cols.push(
                        <TableCell className='col-cell' key={check_index} style={{padding:4}}>
                            <Checkbox
                                color="primary"
                                size="small"
                                checked={this.props.checked_rows && this.props.checked_rows.indexOf(row_data) > -1 ? true : false}
                                onChange={(e) => {
                                    
                                    this.props.onRowCheck(row_data, _column, _row_data)
                                }}
                            />
                        </TableCell>
                    )
                }else if(column.renderer){

                    if(typeof column.renderer == 'function'){

                        cols.push(
                            <EditableCell
                                original_value={original_value}
                                key={row_index}
                                column={column}
                                row={_row}
                                num={_n}
                                row_data={row_data}
                                value={column.renderer(_row, _n, row_data)}
                                account_token={this.props.account_token}
                                onCancel={() => {

                                    this.setState({selected_row_index: false})
                                }}

                                cell_update_url={column.hasOwnProperty('cell_update_url') ? column.cell_update_url : this.props.cell_update_url}
                                onUpdate={(row_data, column) => {

                                    this.props.onUpdate(row_data, column)
                                }}
                            />
                        )
                    }else{
                        cols.push(
                            <EditableCell
                                original_value={original_value}
                                key={row_index}
                                column={column}
                                row={_row}
                                num={_n}
                                row_data={row_data}
                                value={this.props[column.renderer](_row, n, row_data)}
                                account_token={this.props.account_token}
                                onCancel={() => {

                                    this.setState({selected_row_index: false})
                                }}
                                cell_update_url={column.hasOwnProperty('cell_update_url') ? column.cell_update_url : this.props.cell_update_url}
                                onUpdate={(row_data, column) => {

                                    this.props.onUpdate(row_data, column)
                                }}
                            />
                        )
                    }
                }else{
                
                    cols.push(
                        <EditableCell
                            original_value={original_value}
                            key={row_index}
                            column={column}
                            row={_row}
                            num={_n}
                            row_data={row_data}
                            value={row_data}
                            account_token={this.props.account_token}
                            onCancel={() => {

                                this.setState({selected_row_index: false})
                            }}
                            cell_update_url={column.hasOwnProperty('cell_update_url') ? column.cell_update_url : this.props.cell_update_url}
                            onUpdate={(row_data, column) => {

                                this.props.onUpdate(row_data, column)
                            }}
                        />
                    )
                }
            }
        });

        let props = {}

        if(this.props.rowHighlight){

            props.style = {backgroundColor: this.props.rowHighlight(_row, n)}
        }

        return (
            <React.Fragment key={"row_" + "_" + n}>
                <TableRow
                    className={`
                        card has-hoverable-action
                        row_${_n}
                        ${(this.props.active_row === _n || this.props.selected_row_index === _n) ? 'active-row' : ''}
                        ${_n}-${this.props.selected_row_index}
                        ${this.props.expanded_row === n ? 'has-sub-data' : ''}
                    `}
                    {...props}
                >
                    
                    {cols}
                    
                    <TableCell>

                        <div className='space-between'>

                            {this.props.row_actions
                                ?
                                    this.props.row_actions(_row, n)
                                :
                                    <>
                                        {this.props.view_url && this.props.row_id
                                            ?
                                                <>
                                                    <div className="hoverable-action">
                                                        <Link to={"/" + this.props.view_url + "/" + _row[this.props.row_id]}>
                                                            <Btn color="primary" size="small" icon={true}>
                                                                <Edit />
                                                            </Btn>
                                                        </Link>
                                                    </div>
                                                </>
                                            :
                                                null
                                        }
                                    </>
                            }

                            {
                                (
                                    this.props.sub_rows
                                    &&
                                    (
                                        (!this.props.sub_rows.hasOwnProperty('rowValidate'))
                                        ||
                                        (this.props.sub_rows.hasOwnProperty('rowValidate') && this.props.sub_rows.rowValidate(_row) === true)
                                    )
                                )
                            &&

                                <div className="hoverable-action">

                                    <Btn icon={true} size="small" onClick={() => {

                                        if(this.props.expanded_row === n){

                                            this.props.expandRow(false)
                                        }else{
                                        
                                            this.props.expandRow(n)

                                            if(this.props.sub_rows.init_load && this.props.sub_rows.init_load === true){

                                                this.loadSubData(_row[this.props.sub_rows.row_id.key], this.props.sub_rows.row_id.field, this.props.sub_rows.api_url, n)
                                            }
                                        }
                                    }}>

                                        {this.props.expanded_row === n
                                            ?
                                                <ExpandLess style={{fontSize: 20}} />
                                            :
                                                <ExpandMore style={{fontSize: 20}} />
                                        }
                                    </Btn>
                                </div>
                            }
                        </div>
                    </TableCell>
                </TableRow>

                {(this.props.sub_rows && _row.hasOwnProperty('sub_data') && this.props.expanded_row === n) &&
                
                    <DataTableSubRows
                        index={n}
                        row={_row}
                        colSpan={(cols.length) + 1}

                        sub_rows={this.props.sub_rows}
                        columns={this.props.sub_rows.columns}

                        mainAction={() => {

                            this.props.mainAction(_row)
                        }}

                        onClose={() => {

                            this.props.expandRow(false)
                        }}

                        loading={this.state.loading}
                    />
                }

                <TableRow className="data-table-row-spacer">
                    <TableCell colSpan={(cols.length) + 1}></TableCell>
                </TableRow>
            </React.Fragment>
        )
    }

    loadSubData = (row_id, field, url, index) => {

        const formData = new FormData();

        let filters = {};

        filters[field] = row_id;
        
        formData.append("filters", JSON.stringify(filters));
		
		var self = this;
		self.setState({loading: true});
		
        Api.post(url, formData, function (data) {

            self.props.updateSubData(data.records, index)
            self.setState({load_sub_data: false, loading: false})
        });
    }
}

export default DataTableRows;
