import React, { Component } from 'react';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';

import Add from '@mui/icons-material/Add';

import Btn from 'components/Btn';

import Loader from 'components/Loader';

import DataTableRows from './DataTableRows';
import DataTableHeaders from './DataTableHeaders';

class DataTableSubRows extends Component { 
    constructor(props) {
        super();

        this.state = {
        }
    }

    componentDidMount = () => {
    }

    renderData = () => {

        var columns = this.props.columns;

        let rows = [];
        
        if(columns.length > 0){

            var data = this.props.row.sub_data;

            if(data && data.length > 0){

                for(var n in data){

                    rows.push(
                        <DataTableRows
                            key={`datatable_row_${n}_${this.props.index}`}
                            index={n}
                            data={data}
                            columns={columns}
                            selected_row_index={false}
                            active_row={false}
                            sub_rows={false}
                        />
                    )
                }
            }
        }

        return rows;
    }

    render(){

        return(

            (this.props.row.hasOwnProperty('sub_data')) &&

                <TableRow className={`card has-hoverable-action card-sub-rows`}>

                    <TableCell colSpan={this.props.colSpan} style={{position: 'relative'}}>

                        {this.props.sub_rows.hasOwnProperty('title') &&
                        
                            <div className='space-between mb-5'>

                                <strong className='fs-13 op-9'>{this.props.sub_rows.hasOwnProperty('title') ? this.props.sub_rows.title : ''}</strong>

                                <div className='align-center'>
                                    <Btn size="small" startIcon={<Add />} onClick={() => {

                                        this.props.mainAction()
                                    }}>
                                        {this.props.sub_rows.hasOwnProperty('title') ? `Add New ${this.props.sub_rows.title}` : 'Add New Data'}
                                    </Btn>

                                    {/* <Btn className="ml-5" icon={true} size="small" onClick={() => {

                                        this.props.onClose()
                                    }}>
                                        <Close style={{fontSize: 18}} />
                                    </Btn> */}
                                </div> 
                            </div>
                        }

                        <Table>

                            <TableHead>
                                <DataTableHeaders
                                    columns={this.props.columns}
                                    type="sub_rows"
                                />
                            </TableHead>

                            <TableBody>

                                {this.props.row.sub_data.length > 0
                                    ?
                                
                                        this.renderData()
                                    :
                                        <TableRow className="card">
                                            <TableCell colSpan={this.props.colSpan}>
                                                <div className='align-center'>
                                                    <div className='align-center vertical'>
                                                        <strong className='gr-5'>{this.props.sub_rows.hasOwnProperty('title') ? `${this.props.sub_rows.title} not found` : 'Data not found'}</strong>

                                                        <Btn className="mt-10" size="small" startIcon={<Add />} onClick={() => {

                                                            this.props.mainAction()
                                                        }}>
                                                            {this.props.sub_rows.hasOwnProperty('title') ? `Add New ${this.props.sub_rows.title}` : 'Add New Data'}
                                                        </Btn>
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>

                        <Loader loading={this.props.loading} />
                        
                    </TableCell>
                </TableRow>
        )
    }

    renderHeaders = () => {

        var columns = this.props.columns;
        
        if(columns.length > 0){

            var cols = [];

            for(var i in columns){

                var column = columns[i];
                
                if(column && typeof column == 'object' && column.hasOwnProperty('column')){
                
                    cols.push(

                        this.renderHeaderCell(column, i)
                    )
                }
            }

            return (
                <TableRow className="card">
                    {cols}
                    <TableCell></TableCell>
                </TableRow>
            )
        }
    }
}

export default DataTableSubRows;
