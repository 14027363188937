import React, { Component } from 'react';

import { Link, Navigate } from "react-router-dom";

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

import LockOutlined from '@mui/icons-material/LockOutlined';
import LockOpenOutlined from '@mui/icons-material/LockOpenOutlined';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Drafts from '@mui/icons-material/Drafts';

import ValidatorHelper from 'helpers/ValidatorHelper';

import Btn from 'components/Btn';
import Api from 'api/Api';

import bg from 'assets/images/bg.jpg';
import logo from 'assets/images/logo.png?v=1';

class Signin extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,
            user: false,

            loading: false,

            logged_in: false,

            error_message: '',
            success_message: '',

            email: '',
            mobile: '',
            password: '',

            email_error: false,
            mobile_error: false,
            password_error: false,

            todays_attendance: false,

            forgot_password: false,
            forgot_password_message: false,

            forgot_password_message_l1: '',
            forgot_password_message_l2: '',
        }
    }

    componentDidMount = () => {
        
        var account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        
        if(account_token){
            
            this.setState({logged_in: true})
        }
    }

    loginSubmit = (event) => {

        if(this.state.forgot_password){

            this.forgotPassword(event)
            return;
        }

        event.preventDefault();

        var _has_error = false;

        const email = this.state.email;
        const mobile = this.state.mobile;
        const password = this.state.password;

        if(email === '' || !ValidatorHelper.validEmail(email)){

            this.setState({email_error: true})
            _has_error = true;
        }else{

            this.setState({email_error: false})
            _has_error = (!_has_error) ? false : true;
        }

        // if(mobile === '' || isNaN(mobile) || mobile.length < 10){

        //     this.setState({mobile_error: true})
        //     _has_error = true;
        // }else{

        //     this.setState({mobile_error: false})
        //     _has_error = (!_has_error) ? false : true;
        // }

        if(password === ''){

            this.setState({password_error: true})
            _has_error = true;
        }else{

            this.setState({password_error: false})
            _has_error = (!_has_error) ? false : true;
        }
        
        if(!_has_error){

            var that = this;

            var formData = new FormData();

            formData.append('email', email);
            formData.append('password', password);

            this.setState({loading: true})

            Api.post('auth/login', formData, function(data){

                that.setState({loading: false});

                if(data.status){
                
                    localStorage.setItem(process.env.REACT_APP_ACCOUNT_TOKEN, data.account_token);
                    localStorage.setItem(process.env.REACT_APP_ACCOUNT_USER, JSON.stringify(data.user));

                    localStorage.setItem('flash_success_message', data.message);
                    that.setState({success_message: data.message}, () => {

                        that.setState({logged_in: true})
                    })

                }else{

                    that.setState({error_message: data.message})
                    window.setTimeout(() => {

                        that.setState({error_message: ''})
                    }, 5000)
                }
            });
        }
    }

    render(){

        if(this.state.logged_in){

            return <Navigate to="/dashboard" />
        }
    
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                    
                    <div className='gr'>
                        <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            
                            <div className='login-container' style={{backgroundImage: `url(${bg})`}}>
                                {/* <img src={bg} /> */}
                            </div>

                            <div className='login-box'>
                                <Paper elevation={3} square={true} sx={{padding: 10, borderRadius: 6, width: '100%', backgroundColor: '#fff'}}>
                            
                                    <form onSubmit={this.loginSubmit.bind(this)}>
                                    
                                        <Grid container spacing={3}>

                                            {(this.state.error_message !== '' || this.state.success_message !== '') &&
                                            
                                                <Grid item md={12}>

                                                    {this.state.error_message !== '' &&
                                                    
                                                        <Alert severity="error" onClick={() => {

                                                            this.setState({error_message: ''})
                                                        }}>{this.state.error_message}</Alert>
                                                    }

                                                    {this.state.success_message !== '' &&
                                                    
                                                        <Alert severity="success" onClick={() => {

                                                            this.setState({success_message: ''})
                                                        }}>{this.state.success_message}</Alert>
                                                    }
                                                </Grid>
                                            }

                                            <Grid item xs={12}>
                                                <div className='logo'>
                                                    <img src={logo} />
                                                </div>
                                            </Grid>

                                            {!this.state.forgot_password_message
                                                ?
                                                    <>
                                                        <Grid item md={12}>

                                                            <div className='justify-start'>

                                                                {this.state.forgot_password
                                                                    ?
                                                                        <LockOpenOutlined style={{fontSize:50}} className="op-3" />
                                                                    :
                                                                        <LockOutlined style={{fontSize:50}} className="op-3" />
                                                                }

                                                                <div className='flex-col ml-5'>
                                                                    {this.state.forgot_password
                                                                        ?
                                                                            <>
                                                                                <strong className='fs-24'>Forgot Password?</strong>
                                                                                <span className='fs-16 gr-5 fw-semibold'>Recover your password.</span>
                                                                            </>
                                                                        :
                                                                            <>
                                                                                <strong className='fs-24'>Welcome</strong>
                                                                                <span className='fs-16 gr-5 fw-semibold'>Please login.</span>
                                                                            </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item md={12}>

                                                            {/* <TextField
                                                                label="Mobile Number"
                                                                variant="standard"
                                                                value={this.state.mobile}
                                                                onChange={(e) => {

                                                                    this.setState({mobile: e.target.value})
                                                                }}
                                                                error={this.state.mobile_error}
                                                                fullWidth
                                                                helperText={this.state.mobile_error ? 'Please enter valid mobile number' : ''}
                                                                autoComplete="off"
                                                            /> */}
                                                            <TextField
                                                                label="Email Address"
                                                                variant="standard"
                                                                value={this.state.email}
                                                                onChange={(e) => {

                                                                    this.setState({email: e.target.value})
                                                                }}
                                                                error={this.state.email_error}
                                                                fullWidth
                                                                helperText={this.state.email_error ? 'Please enter valid email address' : ''}
                                                                autoComplete="off"
                                                            />
                                                        </Grid>

                                                        {this.state.forgot_password === false &&
                                                        
                                                            <Grid item md={12}>

                                                                <TextField
                                                                    label="Password"
                                                                    variant="standard"
                                                                    type="password"
                                                                    value={this.state.password}
                                                                    onChange={(e) => {

                                                                        this.setState({password: e.target.value})
                                                                    }}
                                                                    error={this.state.password_error}
                                                                    fullWidth
                                                                    helperText={this.state.password_error ? 'Please enter valid password' : ''}
                                                                    autoComplete="off"
                                                                />
                                                            </Grid>
                                                        }

                                                        <Grid item md={12} sx={{marginTop: 3}}>

                                                            <div className='vertical'>
                                                            
                                                                <Btn color="primary" variant='contained' sx={{width: '100%', padding: '10px'}} type="submit" loading={this.state.loading}>{this.state.forgot_password ? 'Send Password Email' : 'LOGIN'}</Btn>

                                                                {this.state.forgot_password
                                                                    ?
                                                                        <Btn startIcon={<ArrowBack />} className="mt-10" onClick={() => {

                                                                            this.setState({forgot_password: false, email: '', email_error: ''})
                                                                        }}>Back to Login</Btn>
                                                                    :
                                                                        <Btn className="mt-10" onClick={() => {

                                                                            this.setState({forgot_password: true, email: '', email_error: false, password: '', password_error: false})
                                                                        }}>Forgot Password?</Btn>
                                                                }
                                                            </div>
                                                        </Grid>
                                                    </>
                                                :
                                                    <>
                                                        <Grid item xs={12}>
                                                            <div className='vertical align-center'>
                                                                <div style={{width: 200, height: 200, borderRadius: '100%', backgroundColor: 'rgba(0, 139, 139, .1)'}} className='align-center'>
                                                                    <Drafts className='c-green' style={{fontSize: 100}} />
                                                                </div>

                                                                <div className='align-center vertical'>
                                                                    <div className='fs-18 fw-semibold mt-20 c-green text-center'>{this.state.forgot_password_message_l1}</div>
                                                                    <div className='fs-14 fw-semibold mt-10 gr-6 text-center'>{this.state.forgot_password_message_l2}</div>
                                                                </div>

                                                                <Btn startIcon={<ArrowBack />} className="mt-20" onClick={() => {

                                                                    this.setState({forgot_password: false, forgot_password_message: false})
                                                                }}>Back to Login</Btn>
                                                            </div>
                                                        </Grid>
                                                    </>
                                            }
                                        </Grid>
                                    </form>
                                </Paper>
                            </div>
                        </Box>
                    </div>
                </Grid>
            </Grid>
        )
    }

    forgotPassword = (event) => {

        event.preventDefault();

        var _has_error = false;

        const email = this.state.email;

        if(email === '' || !ValidatorHelper.validEmail(email)){

            this.setState({email_error: true})
            _has_error = true;
        }else{

            this.setState({email_error: false})
            _has_error = (!_has_error) ? false : true;
        }
        
        if(!_has_error){

            var that = this;

            var formData = new FormData();

            formData.append('email', email);

            this.setState({loading: true})

            Api.post('auth/forgot_password', formData, function(data){

                that.setState({loading: false});

                if(data.status){

                    that.setState({forgot_password_message_l1: data.message_1, forgot_password_message_l2: data.message_2, forgot_password_message: true, email: ''})

                }else{

                    that.setState({error_message: data.message})
                    window.setTimeout(() => {

                        that.setState({error_message: ''})
                    }, 5000)
                }
            });
        }
    }
}

export default Signin;