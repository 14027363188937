import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';

import Popover from '@mui/material/Popover';

import Chip from '@mui/material/Chip';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Edit from '@mui/icons-material/Edit';

import Btn from 'components/Btn';
import Loader from 'components/Loader';

import Api from 'api/Api';

import 'assets/styles/datatable.css';

class EditableCell extends Component { 
    constructor(props) {
        super();

        this.state = {

            editable_cell: false,

            selected_row_data: false,

            value: '',

            updating: false,

            flash_success_message: '',
            flash_error_message: ''
        }
    }

    renderLabel = () => {

        if(this.props.column.hasOwnProperty('chip_colors')){

            let chip_colors = this.props.column.chip_colors;

            if(chip_colors.hasOwnProperty(this.props.original_value)){

                return <Chip size="small" color={chip_colors[this.props.original_value]} label={<span className='fs-12'>{this.props.value}</span>} />
            }
        }else{

            return <div>{this.props.value}</div>
        }
    }

    render () {
        
        let _props = {}

        if(this.props.column.hasOwnProperty('width')){

            _props['width'] = this.props.column.width
        }

        let input_type = 'input';

        if(this.state.selected_row_data.input_type === 'input' || this.state.selected_row_data.input_type === 'textarea'){

            input_type = this.state.selected_row_data.input_type;
        }

        return (
            <>
                <TableCell className='col-cell' {..._props}>

                    {this.renderLabel()}

                    {
                        (
                            (this.props.column.hasOwnProperty('editable'))
                            &&
                            (
                                (typeof this.props.column.editable === 'boolean' && this.props.column.editable === true)
                                ||
                                (typeof this.props.column.editable === 'function' && this.props.column.editable(this.props.row) === true)
                            )
                        )
                        &&
                
                        <div className='col-cell-edit-action'>
                            <IconButton size="small" title={`Edit ${this.props.column.name}`} onClick={(e) => {

                                if(this.props.column.hasOwnProperty('onEdit')){

                                    this.props.column.onEdit(e, this.props.column, this.props.row)
                                }else{
                                
                                    this.setState({
                                        selected_row_data: this.props.column,
                                        editable_cell: e.target,
                                        value: this.props.row_data
                                    })
                                }
                            }}>
                                <Edit style={{fontSize: 16}} />
                            </IconButton>
                        </div>
                    }

                    <Popover
                        open={this.state.editable_cell ? true : false}
                        anchorEl={this.state.editable_cell}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <Paper elevation={3} sx={{backgroundColor: '#fff', borderRadius: '10px', width: 400}}>

                            <Box sx={{padding: '10px 20px', borderBottom: '1px solid rgba(0,0,0,.1)', backgroundColor: 'rgba(0,0,0,.03)', borderRadius: '10px 10px 0 0'}}>
                                <h2 className='fs-14' style={{margin: 0}}>Update {this.props.column.name}</h2>
                            </Box>
                            <Box sx={{padding: '20px 20px'}}>
                                
                                <form onSubmit={this.formSubmit.bind(this)}>
                                    <Grid container spacing={2} className="">

                                        <Grid item xs={12} lg={12}>
                                            {this.state.selected_row_data !== false &&
                                            
                                                <>
                                                    {
                                                        (
                                                            input_type === 'input'
                                                            ||
                                                            input_type === 'textarea'
                                                        )
                                                    &&
                                                    
                                                        <TextField
                                                            placeholder={`Update ${this.props.column.name}`}
                                                            fullWidth
                                                            label={this.props.column.name}
                                                            size="small"
                                                            value={this.state.value}
                                                            style={{fontSize:14}}
                                                            onChange={(e) => {

                                                                this.setState({value: e.target.value})
                                                            }}
                                                            autoComplete="off"
                                                            multiline={input_type === 'textarea' ? true : false}
                                                            rows={input_type === 'textarea' ? 6 : 1}
                                                        />
                                                    }
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                            <Box sx={{padding: '10px 20px', borderTop: '1px solid rgba(0,0,0,.1)', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                                <Btn size="small" confirm={true} confirm_message="Do you really want to cancel? Edited data will be lost." onClick={() => {

                                    this.setState({
                                        editable_cell: false,
                                        selected_row_data: false,
                                        selected_row_index: false,
                                        value: ''
                                    })

                                    this.props.onCancel()
                                }}>Cancel</Btn>

                                <Btn size="small" color="secondary" variant="outlined" onClick={() => {

                                    this.update(this.props.column)
                                }}>Update</Btn>
                            </Box>

                            <Loader loading={this.state.updating} />
                        </Paper>
                    </Popover>

                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={this.state.flash_success_message !== '' ? true : false}
                        autoHideDuration={5000}
                        key={"success_message"}
                        onClose={() => {

                            this.setState({flash_success_message: ''})
                        }}
                    >
                        <Alert elevation={6} variant="filled" severity="success">{this.state.flash_success_message}</Alert>
                    </Snackbar>

                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={this.state.flash_error_message !== '' ? true : false}
                        autoHideDuration={5000}
                        key={"error_message"}
                        onClose={() => {

                            this.setState({flash_error_message: ''})
                        }}
                    >
                        <Alert elevation={6} variant="filled" severity="error">{this.state.flash_error_message}</Alert>
                    </Snackbar>
                </TableCell>
            </>
        )
    }

    formSubmit = (event) => {

        event.preventDefault();

        this.update(this.props.column)
    }

    update = (column) => {

        const row = this.props.row;
        const _row_id = row[column.row_id];

        var that = this;

        var formData = new FormData();

        formData.append('account_token', this.props.account_token);
        formData.append(column.column, this.state.value);
        formData.append('row_id', _row_id);

        that.setState({updating: true});

        Api.post(this.props.cell_update_url, formData, function(data){

            that.setState({updating: false});

            if(data.status){

                that.props.onUpdate(data.row, column);
                that.props.onCancel()

                that.setState({
                    editable_cell: false,
                    selected_row_data: false,
                    selected_row_index: false,
                    value: '',
                    flash_success_message: data.message
                })

            }else{

                that.setState({flash_error_message: data.message})
            }
        });
    }
}

export default EditableCell;
