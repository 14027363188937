import React, { Component } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

class Loader extends Component { 
    constructor(props) {
        super();
    }

    render () {
        
        return (

            <>
                {this.props.loading === true
                    ?
                        <div className="loader flex-center vertical">
                            <CircularProgress size={this.props.size || 30} color={this.props.color ? this.props.color : 'secondary'} />

                            {(this.props.message && this.props.message !== '') &&
                            
                                <span className='mt-10 fw-semibold fs-12 gr-6'>{this.props.message}</span>
                            }

                            {(this.props.children) &&
                            
                                this.props.children
                            }
                        </div>
                    :
                        null
                }
            </>
        )
    }
}

export default Loader;