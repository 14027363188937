import React from "react";

import Person from '@mui/icons-material/Person';

class ProfilePic extends React.Component {

    render() {

        let dim = 70;
        let fontSize = 60;

        if(this.props.size === 'small'){

            dim = 50
            fontSize = 30
        }
    
        return (
            <div style={{width:dim, height:dim, borderRadius:15, backgroundColor:'rgba(255, 255, 255, .3)', overflow:'hidden'}} className='align-center'>

                {this.props.profile_pic_url !== ''
                    ?
                        <div style={{backgroundImage: `url(${this.props.profile_pic_url})`, width: '100%', height: '100%', backgroundPosition:'center center', backgroundSize:'cover'}}></div>
                    :
                        <Person style={{fontSize:fontSize}} className='gr-2' />
                }
            </div>
        )
    }
}

export default ProfilePic;