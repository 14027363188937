import React, { Component } from 'react';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';

import Button from '@mui/material/Button';

import ExpandMore from '@mui/icons-material/ExpandMore';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';

class DataTableHeaders extends Component { 
    constructor(props) {
        super();

        this.state = {
        }
    }

    render(){

        return(

            this.renderHeaders()
        )
    }

    renderHeaders = () => {

        var columns = this.props.columns;

        if(columns.length > 0){

            var cols = [];

            for(var i in columns){

                var column = columns[i];
                
                if(column && typeof column == 'object' && column.hasOwnProperty('column')){
                
                    cols.push(

                        this.renderHeaderCell(column, i)
                    )
                }
            }

            return (
                <TableRow className={`card ${this.props.type && this.props.type === 'sub_rows' ? 'sub-rows-header' : ''}`}>
                    {cols}
                    <TableCell></TableCell>
                </TableRow>
            )
        }
    }

    renderHeaderCell = (column, index) => {

        const header_action = (column.hasOwnProperty('header_action') ? column.header_action : null)

        if(column.selectable){

            return (
                <TableCell key={"heading_check_all"} style={{padding:'8px 4px'}}>
                    <Button size="small" onClick={(e) => {

                        this.props.checkAll(e)
                        
                    }}>
                        {this.props.select_all || this.props.main_checked
                            ?
                                <CheckBoxIcon style={{fontSize: 18, color:'#3f51b5'}} className='fs-12' />
                            :
                                <>
                                    <CheckBoxOutlineBlank style={{fontSize: 18}} className='fs-12 op-6' />
                                    <ExpandMore style={{fontSize: 16}} className='fs-12 op-6' />
                                </>
                        }
                    </Button>
                </TableCell>
            )
        }else if(column.sortable){

            return (
                <TableCell key={`${column.column}_${index}`}>
                    <TableSortLabel
                        active={this.props.column_sort_by == (column.field || column.column)}
                        direction={this.props.sort_by_dir}
                        onClick={(e) => {

                            this.props.sortColumn(column)
                        }}
                    >
                        {column.name}
                    </TableSortLabel>
                </TableCell>
            )
        }else{

            return (
                <TableCell key={`${column.column}_${index}`}>
                    <div className='justify-start'>
                        <span>{column.name}</span>
                        {header_action}
                    </div>
                </TableCell>
            )
        }
    }
}

export default DataTableHeaders;
