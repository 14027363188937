import React, { Component, createRef } from 'react';
import { Navigate } from "react-router-dom";

import DataTable from 'components/blocks/data_table/DataTable';
import WdForm from 'components/wd/WdForm';

import IconButton from '@mui/material/IconButton';

import Main from 'components/Main';

import Api from 'api/Api';

import Edit from '@mui/icons-material/Edit';

import LayoutHelper from 'helpers/LayoutHelper';

import Ratings from 'components/Ratings';

class ReviewsList extends Component { 
    constructor(props) {
        super();
        this.state = {

            account_token: '',
            error_message: '',
            success_message: '',

            loading: false,

            user: false,

            row: false,
            active_row: false,

            row_data: false,

            do_reload: false,

            anchorEl: null,

            redirect: '',

            form_popup: false,
            
            states: [],
        }

        this.filter = createRef();
    }

    componentDidMount = () => {

        let account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        let user = localStorage.getItem(process.env.REACT_APP_ACCOUNT_USER);
        
        if(account_token && user){

            this.setState({account_token: account_token}, () => {

                this.init(account_token)
            });
        }else{

            // window.location = Api.url + 'logout';
        }
    }

    render () {

        if(this.state.redirect !== ''){

            return <Navigate to={`/${this.state.redirect}`} />
        }

        return (

            <Main
                active_page="professionals"

                error_message={this.state.error_message}
                success_message={this.state.success_message}

                page="professionals_reviews"
                title={"Reviews"}
            >

                {(this.state.account_token) &&

                    <>

                        <DataTable
                            index="professionals_reviews"
                            label="Reviews"

                            active_row={this.state.active_row}

                            do_reload={this.state.do_reload}
                            relaodDone={() => {

                                this.setState({do_reload: false});
                            }}

                            // filter_params={this.state.filter_params}

                            columns={[
                                {name: 'Professional First Name', column: 'first_name', sortable: true},
                                {name: 'Professional Last Name', column: 'last_name', sortable: true},
                                {name: 'Reviewer Name', column: 'name', sortable: true},
                                {name: 'Reviewer Email', column: 'email', sortable: true},
                                {name: 'Ratings', column: 'ratings', sortable: true, renderer: (row) => {

                                    return <Ratings rating={row.rating} />
                                }},
                                {name: 'Review', column: 'review', sortable: false},
                                {name: 'Status', column: 'status', db_column: 'reviews.status', search_type: 'match', search_input: 'dropdown', search_data: [{key: '0', value: 'Not Verified'}, {key: '1', value: 'Enabled'}, {key: '2', value: 'Disabled'}]},
                                {name: 'Date', column: 'added_on', sortable: true, width: 100, renderer: (row) => {

                                    return <span>{row.added_on_formatted}</span>
                                }},
                            ]}

                            row_actions={(row, row_index) => {

                                return (

                                    <div className="hoverable-action">
                                        <div className="align-start">

                                            <IconButton size="small" color="primary" onClick={(e) => {

                                                this.setState({row: row, active_row: row_index}, () => {

                                                    this.setState({form_popup: true})
                                                })
                                            }}>
                                                <Edit style={{fontSize: 18}} />
                                            </IconButton>
                                        </div>
                                    </div>
                                )
                            }}

                            per_page={12}

                            default_sort_by="added_on"
                            sort_by_dir="desc"

                            api_url="backend/professionals/reviews/list"

                            account_token={this.state.account_token}
                            
                            row_id="row_id"
                            view_url="employees/leave_application/listing"
                        />

                        <WdForm
                            
                            title='Review'
                            back_label="Cancel"

                            drawer={true}
                            open={this.state.form_popup}

                            size="medium"

                            submit_url='backend/professionals/reviews/save'
                            data_url='backend/professionals/reviews/data'

                            submit_continue={true}

                            onSubmit={(result) => {

                                this.setState({form_popup: false, do_reload: true, active_row: false, row_id: '', row: false})
                                LayoutHelper.addSuccessMessage(this, result.message)
                            }}
                            onBack={() => {

                                this.setState({form_popup: false, active_row: false, row_id: '', row: false})
                            }}

                            onDataLoad={(row_data, data) => {

                                this.setState({row_data: row_data})
                            }}
                        
                            row_id={this.state.row.row_id}
                            id="row_id"
                            title_field="review_by"
                            updated_on="updated_on_formatted"
                                                    
                            fields={{
                                rows: [
                                    {
                                        fields: [
                                            {key: 'added_on', type: 'html', label: 'Posted On', span: 12, html: (
                                                <div>
                                                    {this.state.row_data !== false &&
                                                    
                                                        <div className='flex-col'>
                                                            <strong className='fs-14'>Posted On</strong>
                                                            <span className='fs-16'>{this.state.row_data.added_on_formatted}</span>
                                                        </div>
                                                    }
                                                </div>
                                            )},
                                        ]
                                    },
                                    {
                                        fields: [
                                            {key: 'status', type: 'radio', name: 'status', label: 'Status', validations: ['r'], span: 12, options: [{key: '1', value: 'Enabled'}, {key: '2', value: 'Disabled'}]},
                                        ]
                                    },
                                    {
                                        fields: [
                                            {key: 'name', type: 'input', name: 'name', label: 'Name', validations: ['r'], span: 12},
                                        ]
                                    },
                                    {
                                        fields: [
                                            {key: 'email', type: 'input', name: 'email', label: 'Email', validations: ['r', 'email'], span: 12},
                                        ]
                                    },
                                    {
                                        fields: [
                                            {key: 'rating', type: 'radio', name: 'rating', label: 'Rating', validations: ['r'], span: 12, options: [{key: '1', value: '1 Star'}, {key: '2', value: '2 Star'}, {key: '3', value: '3 Star'}, {key: '4', value: '4 Star'}, {key: '5', value: '5 Star'}]},
                                        ]
                                    },
                                    {
                                        fields: [
                                            {key: 'review', type: 'textarea', name: 'review', label: 'Review', validations: ['r'], rows: 10, span: 12},
                                        ]
                                    },
                                ]
                            }}
                        />
                    </>
                }
            </Main>
        )
    }

    init = (account_token) => {

        var formData = new FormData();
        var that = this;

        formData.append('account_token', account_token);

        Api.post('backend/professionals/init', formData, function(data){

            if(data.status){

                let indian_states = data.states.filter(item => item.country === 'IN');
            
                that.setState({
                    states: indian_states
                });

                const personal_trainer = data.specialisation_options.filter(item => item.head === 'personal_trainer');
                const dietician = data.specialisation_options.filter(item => item.head === 'nutritionist');
                const management = data.specialisation_options.filter(item => item.head === 'management');
                const yoga = data.specialisation_options.filter(item => item.head === 'yoga');
                const physiotherapy = data.specialisation_options.filter(item => item.head === 'physiotherapy');

                that.setState({
                    specialisation_options_personal_trainer: personal_trainer,
                    specialisation_options_dietician: dietician,
                    specialisation_options_management: management,
                    specialisation_options_yoga: yoga,
                    specialisation_options_physiotherapy: physiotherapy,
                });

            }else{

                // LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }
}

export default ReviewsList;
