
import './assets/styles/theme.css';

import { ThemeProvider, createTheme } from '@mui/material/styles'

import {BrowserRouter, Route, Routes as Switch} from 'react-router-dom';

import Signin from 'pages/auth/Signin';
import Logout from 'pages/auth/Logout';
import ResetPassword from 'pages/auth/ResetPassword';

import Dashboard from 'pages/app/Dashboard';

import ProfessionalsList from 'pages/app/professionals/ProfessionalsList';
import ReviewsList from 'pages/app/reviews/ReviewsList';

import JobsListing from 'pages/app/jobs/JobsListing';

import CmsPagesList from 'pages/app/cms/pages/CmsPagesList';
import CmsSections from 'pages/app/cms/sections/CmsSections';

import EmailTemplates from 'pages/app/cms/email/EmailTemplates';
import EmailTemplatesAdd from 'pages/app/cms/email/EmailTemplatesAdd';

import SettingsPageMain from 'pages/app/settings/SettingsPageMain';

import WdFormRouter from 'pages/app/WdFormRouter';

function App(){

    const theme = createTheme({
        body: {
            fontSize: 12, fontFamily: 'Fira Sans'
        },
        typography: {
            fontFamily: [
                'Fira Sans'
            ],
        },
        shadows: [
            "none",
            "0px 15px 60px rgba(0, 0, 0, 0.25)",
            "5px 25px 60px rgba(0, 0, 0, 0.25)",
            "10px 35px 60px rgba(0, 0, 0, 0.25)",
            "15px 45px 60px rgba(0, 0, 0, 0.25)",
            ...Array(20).fill('none')
        ],
        components: {
            MuiButton: {
                styleOverrides: {
                    root: ({ ownerState }) => ({
                      
                        fontSize: 13,
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 24,
                        paddingRight: 24,
                        transition: '.2s all ease-in-out',
                        borderRadius: 30,
                        fontWeight: 600,
                        textTransform: 'capitalize',
                        color: 'rgba(5, 130, 177, 1)',
                        "&:disabled": {
                            color: "rgba(0, 0, 0, .4)"
                        },

                        ...(ownerState.size === 'small' && {

                            fontSize: 12,
                            paddingTop: 3,
                            paddingBottom: 3,
                            paddingLeft: 8,
                            paddingRight: 8
                        }),
                        ...(ownerState.size === 'large' && {

                            fontSize: 20,
                        }),

                        ...(ownerState.variant === 'contained' && {

                            ...(ownerState.color === 'primary' && {
                                
                                boxShadow: 'none',
                                borderRadius: '50px',
                                fontWeight: "bold",
                                background: 'rgba(5, 130, 177, 1)',
                                "&:hover": {
                                    background: "rgba(0, 91, 152, 1)",
                                    boxShadow: 'none'
                                },
                                color: '#ffffff',
                            }),
                            ...(ownerState.color === 'secondary' && {

                                background: 'rgba(5, 130, 177, 1)',
                                color: '#ffffff'
                            }),
                            ...(ownerState.size === 'small' && {

                                fontSize: 13,
                                paddingTop: 5,
                                paddingBottom: 5,
                                paddingLeft: 18,
                                paddingRight: 18
                            }),
                        }),

                        ...(ownerState.variant === 'outlined' && {

                            ...(ownerState.color === 'secondary' && {

                                background: 'rgb(106, 130, 251, .9)',
                                border: '1px solid transparent',
                                color: '#fff',
                                "&:hover": {
                                    background: "rgb(106, 130, 251, 1)",
                                    border: '1px solid transparent',
                                },
                            }),

                            ...(ownerState.color === 'primary' && {

                                ...(ownerState.size === 'small' && {

                                    background: 'transparent',
                                    border: '1px solid rgb(106, 130, 251, .9)',
                                    color: 'rgb(106, 130, 251, .9)',
                                    "&:hover": {
                                        background: "rgb(106, 130, 251, 1)",
                                        border: '1px solid rgb(106, 130, 251, .9)',
                                        color: '#fff',
                                    },
                                    paddingTop: 1,
                                    paddingBottom: 1,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    fontSize: 12,
                                    alignSelf: 'center'
                                })
                            }),
                        }),
                    })
                }
            },
            MuiCircularProgress: {
                styleOverrides: {

                    root: ({ ownerState }) => ({

                        ...(ownerState.color === 'primary' && {

                            color: '#ffffff'
                        }),
                        ...(ownerState.color === 'secondary' && {

                            color: 'rgb(42,35,108)'
                        })
                    })
                }
            },
            MuiPaper: {
                styleOverrides: {

                    root: {
                        backgroundColor: '#ffffff'
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {

                    root: {
                        backgroundColor: '#fff'
                    },
                    notchedOutline: {
                        borderWidth: 1,
                        borderRadius: 0
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {

                    inputSizeSmall: {
                        height: '1.5rem',
                        fontSize: 15,
                        borderRadius: 0
                    }
                }
            },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        minHeight: '50px'
                    }
                }
            },
            MuiChip: {
                styleOverrides: {

                    root: ({ ownerState }) => ({

                        borderRadius: 5,

                        ...(ownerState.color === 'success' && {

                            backgroundColor: '#00c2a8'
                        }),

                        ...(ownerState.size === 'small' && {

                            padding: '2px 4px',
                            height: 'auto',
                            fontWeight: 'bold',
                            fontSize: 10,
                            textTransform: 'uppercase'
                        }),
                    })
                }
            },
        }
    })

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter basename={'/'}>

                <Switch>
                
                    <Route exact={true} path='/' element={<Signin />} />

                    <Route exact={true} path='/account/reset_password' element={<ResetPassword />} />

                    <Route path="/logout" element={<Logout />}>
                            
                        <Route path=':auto_logout' element={<Logout />} />
                    </Route>

                    <Route exact={true} path='/dashboard' element={<Dashboard />} />

                    <Route exact={true} path='/professionals' element={<ProfessionalsList />} />

                    <Route exact={true} path='/professionals/reviews' element={<ReviewsList />} />

                    <Route exact={true} path='/jobs' element={<JobsListing />} />

                    <Route exact={true} path='/cms/pages' element={<CmsPagesList />} />
                    <Route exact={true} path='/cms/sections' element={<CmsSections />} />

                    <Route exact={true} path='/cms/emails' element={<EmailTemplates />} />
                    
                    <Route path="/cms/emails/add" element={<EmailTemplatesAdd />}>
                        <Route path=':row_id' element={<EmailTemplatesAdd />} />
                    </Route>

                    <Route exact={true} path='/settings' element={<SettingsPageMain />} />

                    <Route exact={true} path='/edit' element={<WdFormRouter />}>
                        <Route path=':main_route' element={<WdFormRouter />}>
                            <Route path=':module' element={<WdFormRouter />}>
                                <Route path=':action' element={<WdFormRouter />}>
                                    <Route path=':row_id' element={<WdFormRouter />} />
                                </Route>
                            </Route>
                        </Route>
                    </Route>

                    <Route exact={true} path='/edit' element={<WdFormRouter />}>
                        <Route path=':main_route' element={<WdFormRouter />}>
                            <Route path=':module' element={<WdFormRouter />}>
                                <Route path=':action' element={<WdFormRouter />}>
                                    <Route path=':sub_action' element={<WdFormRouter />}>
                                        <Route path=':row_id' element={<WdFormRouter />} />
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
