import React, { Component } from 'react';

import { Link, Navigate } from "react-router-dom";

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

import SecurityOutlined from '@mui/icons-material/SecurityOutlined';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ThumbUp from '@mui/icons-material/ThumbUp';

import Btn from 'components/Btn';
import Api from 'api/Api';

import bg from 'assets/images/bg.jpg';
import logo from 'assets/images/logo.png?v=1';

class ResetPassword extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,
            user: false,

            loading: false,

            error_message: '',
            success_message: '',

            otp: '',
            password: '',
            confirm_password: '',

            otp_error: false,
            password_error: false,
            confirm_password_error: false,

            password_updated: false,

            message_l1: '',
            message_l2: ''
        }
    }

    componentDidMount = () => {
        
        var account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        
        if(account_token){
            
            this.setState({logged_in: true})
        }
    }

    render(){

        if(this.state.logged_in){

            return <Navigate to="/dashboard" />
        }
    
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                    
                    <div className='gr'>
                        <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            
                            <div className='login-container'>
                                <img src={bg} />
                            </div>

                            <div className='login-box'>
                                <Paper elevation={3} square={true} sx={{padding: 10, borderRadius: 6, width: '100%', backgroundColor: '#fff'}}>
                            
                                    <Grid container spacing={3}>

                                        {(this.state.error_message !== '' || this.state.success_message !== '') &&
                                        
                                            <Grid item md={12}>

                                                {this.state.error_message !== '' &&
                                                
                                                    <Alert severity="error" onClick={() => {

                                                        this.setState({error_message: ''})
                                                    }}>{this.state.error_message}</Alert>
                                                }

                                                {this.state.success_message !== '' &&
                                                
                                                    <Alert severity="success" onClick={() => {

                                                        this.setState({success_message: ''})
                                                    }}>{this.state.success_message}</Alert>
                                                }
                                            </Grid>
                                        }

                                        <Grid item xs={12}>
                                            <div>
                                                <img src={logo} />
                                            </div>
                                        </Grid>

                                        {!this.state.password_updated
                                            ?
                                                <>
                                                    <Grid item md={12}>

                                                        <div className='justify-start'>

                                                            <SecurityOutlined style={{fontSize:50}} className="op-3" />

                                                            <div className='flex-col ml-5'>
                                                                <strong className='fs-24'>Reset Password</strong>
                                                                <span className='fs-16 gr-5 fw-semibold'>Update your password.</span>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            label="Enter OTP"
                                                            placeholder='Enter OTP from Email'
                                                            variant="standard"
                                                            value={this.state.otp}
                                                            onChange={(e) => {

                                                                this.setState({otp: e.target.value})
                                                            }}
                                                            error={this.state.otp_error}
                                                            fullWidth
                                                            helperText={this.state.otp_error ? 'OTP entered is invalid' : ''}
                                                            autoComplete="off"
                                                        />
                                                    </Grid>

                                                    <Grid item md={12}>
                                                        <TextField
                                                            label="Password"
                                                            variant="standard"
                                                            type="password"
                                                            value={this.state.password}
                                                            onChange={(e) => {

                                                                this.setState({password: e.target.value})
                                                            }}
                                                            error={this.state.password_error}
                                                            fullWidth
                                                            helperText={this.state.password_error ? 'Please enter valid password. Min 6 digits long.' : ''}
                                                            autoComplete="off"
                                                        />
                                                    </Grid>
                                                    <Grid item md={12}>
                                                        <TextField
                                                            label="Confirm Password"
                                                            variant="standard"
                                                            type="password"
                                                            value={this.state.confirm_password}
                                                            onChange={(e) => {

                                                                this.setState({confirm_password: e.target.value})
                                                            }}
                                                            error={this.state.confirm_password_error}
                                                            fullWidth
                                                            helperText={this.state.confirm_password_error ? 'Confirm password must match the password' : ''}
                                                            autoComplete="off"
                                                        />
                                                    </Grid>

                                                    <Grid item md={12} sx={{marginTop: 3}}>

                                                        <div className='vertical'>
                                                        
                                                            <Btn color="primary" variant='contained' sx={{width: '100%', padding: '10px'}} type="submit" loading={this.state.loading} onClick={() => {

                                                                this.submit()
                                                            }}>Reset Password</Btn>
                                                        </div>
                                                    </Grid>
                                                </>
                                            :
                                                <>
                                                    <Grid item xs={12}>
                                                        <div className='vertical align-center'>
                                                            <div style={{width: 200, height: 200, borderRadius: '100%', backgroundColor: 'rgba(0, 139, 139, .1)'}} className='align-center'>
                                                                <ThumbUp className='c-green' style={{fontSize: 100}} />
                                                            </div>

                                                            <div className='align-center vertical'>
                                                                <div className='fs-18 fw-semibold mt-20 c-green text-center'>{this.state.message_l1}</div>
                                                                <div className='fs-14 fw-semibold mt-10 gr-6 text-center'>{this.state.message_l2}</div>
                                                            </div>

                                                            <Btn link={`/`} startIcon={<ArrowBack />} className="mt-20" onClick={() => {

                                                                this.setState({forgot_password: false, forgot_password_message: false})
                                                            }}>Back to Login</Btn>
                                                        </div>
                                                    </Grid>
                                                </>
                                        }
                                    </Grid>
                                </Paper>
                            </div>
                        </Box>
                    </div>
                </Grid>
            </Grid>
        )
    }

    submit = () => {

        var _has_error = false;

        const otp = this.state.otp;
        const password = this.state.password;
        const confirm_password = this.state.confirm_password;

        if(otp === '' || isNaN(otp) || otp.length < 6){

            this.setState({otp_error: true})
            _has_error = true;
        }else{

            this.setState({otp_error: false})
            _has_error = (!_has_error) ? false : true;
        }

        if(password === '' || password.length < 6){

            this.setState({password_error: true})
            _has_error = true;
        }else{

            this.setState({password_error: false})
            _has_error = (!_has_error) ? false : true;
        }

        if(confirm_password === '' || confirm_password !== password){

            this.setState({confirm_password_error: true})
            _has_error = true;
        }else{

            this.setState({confirm_password_error: false})
            _has_error = (!_has_error) ? false : true;
        }
        
        if(!_has_error){

            var that = this;

            var formData = new FormData();

            formData.append('otp', otp);
            formData.append('password', password);
            formData.append('confirm_password', confirm_password);

            this.setState({loading: true})

            Api.post('auth/reset_password', formData, function(data){

                that.setState({loading: false});

                if(data.status){

                    that.setState({message_l1: data.message_1, message_l2: data.message_2, password_updated: true, otp: '', password: '', confirm_password: ''})

                }else{

                    that.setState({error_message: data.message})
                    window.setTimeout(() => {

                        that.setState({error_message: ''})
                    }, 10000)
                }
            });
        }
    }
}

export default ResetPassword;