import React, { Component } from 'react';

import Chip from '@mui/material/Chip';

import Btn from 'components/Btn';

import PinDrop from '@mui/icons-material/PinDrop';

import ProfilePic from 'components/ProfilePic';

import Edit from '@mui/icons-material/Edit';
import Badge from '@mui/icons-material/Badge';

import Chart from 'react-apexcharts'

import Api from 'api/Api';

class ProfessionalsCard extends Component { 
    constructor(props) {
        super();

        this.state = {
        
            show_confirm: false,

            redirect: false,
        }
    }

    render () {

        let options = {
            chart: {
                type: "radialBar",
            },
            colors: ["rgba(62, 147, 252, 1)"],
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: "30%",
                        background: "rgba(0,0,0,0)"
                    },
                    track: {
                        background: 'rgba(0,0,0,.1)',
                        dropShadow: {
                            enabled: false,
                            top: 5,
                            left: 0,
                            blur: 4,
                            opacity: 0.15
                        }
                    },
                    dataLabels: {
                        name: {
                            offsetY: -10,
                            color: "#fff",
                            fontSize: "0px",
                            show: false
                        },
                        value: {
                            offsetY: 5,
                            color: "#050C9C",
                            fontSize: "0px",
                            fontWeight: 'bold',
                            show: true
                        }
                    }
                }
            },
            fill: {
                type: "gradient",
                gradient: {
                    shade: "dark",
                    type: "vertical",
                    gradientToColors: ["rgba(239, 183, 192, 1)"],
                    stops: [0, 100]
                }
            },
            stroke: {
                lineCap: "round"
            },
            labels: ["Progress"]
        };
        
        return (

            <div className='professionals-card'>
                <div className='professionals-card-header'>

                    <div className='professionals-card-media'>

                        <ProfilePic
                            user={this.props.row}
                            profile_pic_url={this.props.row.profile_pic_url}
                        />

                        <div className='professionals-card-name'>

                            <Chip label="Active" size="small" className='mt-5' color="success" />
                            
                            <h4>{this.props.row.first_name} {this.props.row.last_name}</h4>
                            <div className='justify-start'>
                                <PinDrop className='gr-4' style={{fontSize: 18}} />
                                <span className='fs-12'>{this.props.row.city}</span>
                            </div>
                        </div>
                    </div>

                    <div>
                        <Btn title="Public Profile" icon={true} size="small" color="primary" confirm_message="Username is not defined. Please enter username for this profile to view the public profile." confirm={this.props.row.slug !== '' ? false : true} onClick={(e) => {

                            if(this.props.row.slug !== ''){

                                let a = document.createElement('a');
                                a.href = `https://linkfit.in/professional/${this.props.row.slug}`;
                                a.setAttribute('target', '_blank');
                                a.click();
                            }
                        }}>
                            <Badge style={{fontSize: 30}} />
                        </Btn>
                    </div>
                </div>

                <div className='professionals-card-body'>

                    <div className='justify-start'>
                        <div className='professionals-card-stats'>
                            <div className='professionals-card-progress'>

                                <div style={{marginLeft: -50, marginTop: -10}}>
                                    <Chart
                                        type="radialBar"
                                        series={[this.props.row.progress]}
                                        options={options}
                                        width={200}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className='professionals-card-progress-num'>
                                    <strong className='fs-20 gr-5'>{this.props.row.progress}%</strong>
                                </div>
                            </div>
                        </div>

                        <div className='ml-20 vertical'>
                            <div className='ml-20 vertical'>
                                <strong className='gr-8 fs-14'>Current Profession</strong>
                                <span className='fs-13'>
                                    {this.props.row.current_professions_label}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className='professionals-card-bottom justify-between'>

                        {this.props.editable &&
                        
                            <Btn startIcon={<Edit style={{fontSize: 12}} />} onClick={() => {

                                this.props.onEdit(this.props.row)
                            }}>
                                Edit
                            </Btn>
                        }
                    </div>
                </div>
            </div>
        )
    }

}

export default ProfessionalsCard;